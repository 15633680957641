$(document).ready(function(){
  var flag = true;
    $('.tabs__trigger').on('click', function(e){
        e.preventDefault();
        
        var $this = $(this),
            allAccoContent = $('.tabs__content'),
            item = $this.closest('.tabs__item'),
            container = $this.closest('.services'),
            content = container.find('.service__item'),
            ndx = item.index(),
            reqItem = content.eq(ndx),
            activeItem = content.filter('.active'),
            accoContent = item.find('.tabs__content'),
            duration = 500;
            if(!item.hasClass('active')){
              item.addClass('active')
                  .siblings()
                  .removeClass('active');
              if(window.innerWidth < 640){
                allAccoContent.slideUp();
                accoContent.slideDown();
              }else {
                allAccoContent.slideUp();
                content.hide()
                activeItem.fadeOut(duration,function(){
                  reqItem.fadeIn(duration,function(){
                    activeItem.removeAttr('style');
                    $(this).addClass('active')
                          .siblings()
                          .removeClass('active')
                  })
                })

              }

          }else{
            if(window.innerWidth < 640){
              item.removeClass('active');
              accoContent.slideUp(function(){
                $(this).removeAttr('style');
              });
            }
          }
    });

});
$(window).resize(function(){
  var item = $('.tabs__item'),
      tabsContent = $('.service__item');
      allAccoContent = $('.tabs__content');
  if(window.innerWidth > 640){
    allAccoContent.slideUp();
    item.removeClass('active');
    item.first().addClass('active');
    tabsContent.removeClass('active');
    tabsContent.first().addClass('active').fadeIn().siblings().fadeOut(function(){
      $(this).removeAttr('style');
    });
  }else{
    item.removeClass('active');
    allAccoContent.slideUp();
  }
});
