var selector = document.querySelectorAll(".mask__phone"); 
 
var im = new Inputmask("99-9999999"); 
im.mask(selector); 
$(function(){
    $(selector).inputmask({
        mask: '+7(X99) 999-99-99',
        definitions: {
            'X': {
                validator: '9',
                placeholder: '9'
            }
        }
    });
});