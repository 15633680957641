$(window).scroll(function(){
  var wScroll = $(this).scrollTop();
  if (wScroll > $('.header').offset().top - $(window).height()) {
    
      $('.header').each(function(i){
          $('.header').eq(i).addClass('menu__scroll');

      })
      
      if($('.header').offset().top <= 800) {
          $('.header').removeClass('menu__scroll');
      }
  }
});

$(document).ready(function(){
  $('.menu__phone').on('click',function(){
      if(!$('.menu__phone').hasClass('active')){
        $('.menu__phone').addClass('active');
        $('.header__wrap').fadeIn()
          } else {
        $('.menu__phone').removeClass('active');
        $('.header__wrap').fadeOut(function(){
          $(this).removeAttr('style');
        })
      }
});
  $("#nav__menu").on("click","a", function (event) {
    event.preventDefault();
    //идентификатор блока с атрибута href
    var id  = $(this).attr('href'),
    //высоту от начала страницы до блока на который ссылается якорь
      top = $(id).offset().top;
    //анимация перехода на расстояние - top за 1500 мс
    $('body,html').animate({scrollTop: top}, 1500);
  });
});
