$(document).ready(function(){

		$('.btn__form').on('click', function(){
					$('.popup__consultation').fadeIn();
		});

		$('.popup__close').on('click',function(){
			$('.popup__consultation').fadeOut();
			$('.popup-thanks').fadeOut();
			$('.popup__err').fadeOut();
		});
	
		$('.thanks__btn').on('click', function() {
			$('.popup__thanks').fadeOut();
		});
		$('.thanks__err').on('click', function() {
      $('.popup__err').fadeOut();
			$('.popup__consultation').fadeIn();
		});
		$('.thanks__btn-err').on('click',function(){
      $('.popup__error').fadeOut();
		});
    
		$('#popup__form').submit(function(e) {
			var $mainForm = $(this);
			$.ajax({
				type: $mainForm.attr('method'),
				url: $mainForm.attr('action'),
				data: $mainForm.serialize()
			}).done(function() {
				$('.popup__consultation').fadeOut();
				$('.popup__thanks').fadeIn();
				$('form[name=popup__form]').trigger('reset');
			}).fail(function() {
				$('.popup__consultation').fadeOut();
				$('.popup__err').fadeIn();
				$('form[name=popup__form]').trigger('reset');
			});
			//отмена действия по умолчанию для кнопки submit
			e.preventDefault();
		});
		$('.site__form').submit(function(e) {
			var $mainForm = $(this);
			$.ajax({
				type: $mainForm.attr('method'),
				url: $mainForm.attr('action'),
				data: $mainForm.serialize()
			}).done(function() {
				$('.popup__consultation').fadeOut();
				$('.popup__thanks').fadeIn();
				$('form[name=cost__form]').trigger('reset');
			}).fail(function() {
				$('.popup__consultation').fadeOut();
				$('.popup__error').fadeIn();
				$('form[name=cost__form]').trigger('reset');
			});
			//отмена действия по умолчанию для кнопки submit
			e.preventDefault();
		});

});